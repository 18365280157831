import * as yup from "yup";
import type { Asserts } from "yup";

// Response from /banners/:zoneCode validation

export const textTypes = ["title", "content", "subtitle"];

const contentSchema = yup.object().shape({
  type: yup.string().oneOf(textTypes).required(),
  text: yup.string().nullable(),
  fontColorMobile: yup.string().nullable(),
  fontColorDesktop: yup.string().nullable(),
  fontSizeMobile: yup.number().nullable(),
  fontSizeDesktop: yup.number().nullable(),
});

const imageSchema = yup.object().shape({
  path: yup.string().required(),
  type: yup.string().oneOf(["image", "video"]).required(),
});

const bannerSchema = yup.object().shape(
  {
    desktopImage: imageSchema
      .notRequired()
      .when(["mobileImage"], {
        is: (mobileImage) => !mobileImage,
        then: schema => schema.required(),
      })
      .default(undefined),
    mobileImage: imageSchema
      .notRequired()
      .when(["desktopImage"], {
        is: (desktopImage) => !desktopImage,
        then: schema => schema.required(),
      })
      .default(undefined),
    link: yup.string().nullable(),
    texts: yup.array().of(contentSchema),
  },
  [["desktopImage", "mobileImage"]]
);

const bannersSchema = yup.object().shape({
  sectionZone: yup.string().required(),
  sliderSpeed: yup.number().optional(),
  items: yup.array().of(bannerSchema).required(),
});

export interface IBannerContent extends Asserts<typeof contentSchema> {}

export interface IBanner extends Asserts<typeof bannerSchema> {}

export interface IBanners extends Asserts<typeof bannersSchema> {}

const validateBannersResponse = (response: any): IBanners => bannersSchema.validateSync(response);

export default validateBannersResponse;
