import { FC } from "react";

import Image from "@components/Image";

import type { TSlideTypeProps } from "./types";

const ImageSlide: FC<TSlideTypeProps> = ({ url }) => (
  <Image src={url} fill={true} style={{objectFit:"cover"}} alt="" priority />
);
export default ImageSlide;
