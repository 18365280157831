import { FC } from "react";

import { useMultiStyleConfig, Box } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

import type { TSlideTextContentProps } from "./types";
import { TextContentType } from "./types";

const getKey = (i: number): string => `slide-text-${i}`;

const DEFAULT_DESKTOP_FONT_SIZES = {
  [TextContentType.Title]: 18,
  [TextContentType.Content]: 30,
  [TextContentType.Subtitle]: 14,
};

const DEFAULT_MOBILE_FONT_SIZES = {
  [TextContentType.Title]: 16,
  [TextContentType.Content]: 18,
  [TextContentType.Subtitle]: 14,
};

const DEFAULT_FONT_COLOR = "#fff";

const SlideTextContent: FC<TSlideTextContentProps> = ({ content }) => {
  const styles = useMultiStyleConfig("Carousel", {});

  return (
    <Box sx={styles.overlay}>
      {content.map((item, index) => {
        const desktopFontSize = item.fontSizeDesktop || DEFAULT_DESKTOP_FONT_SIZES[item.type];
        const mobileFontSize = item.fontSizeMobile || DEFAULT_MOBILE_FONT_SIZES[item.type];

        const desktopFontColor = item.fontColorDesktop || DEFAULT_FONT_COLOR;
        const mobileFontColor = item.fontColorMobile || DEFAULT_FONT_COLOR;
        return (
          <Box
            key={getKey(index)}
            fontSize={responsiveStyle({ mobile: `${mobileFontSize}px`, desktop: `${desktopFontSize}px` })}
            color={responsiveStyle({ mobile: mobileFontColor, desktop: desktopFontColor })}
            lineHeight={1}
            marginY="0.5rem"
          >
            {item.text}
          </Box>
        );
      })}
    </Box>
  );
};
export default SlideTextContent;
