import { FC } from "react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";

import { Typography } from "@components/common";
import Expand from "@components/Expand";

import { TFaq } from "../types";

type TId = { id: string };
const FaqExpand: FC<TFaq & TId> = ({ question, answer, id }) => {
  const styles = useMultiStyleConfig("Faq", {});
  return (
    <Expand
      defaultIsOpen={false}
      id={id}
      renderHeader={(isOpen) => (
        <Flex>
          <Box sx={styles.icon}>{isOpen ? <MinusIcon boxSize="0.75rem" /> : <AddIcon boxSize="0.75rem" />}</Box>
          <Box sx={styles.question}>
            <Typography sx={isOpen ? styles.expandedQuestionText : styles.questionText}>{question}</Typography>
          </Box>
        </Flex>
      )}
    >
      <Box
        sx={styles.answer}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </Expand>
  );
};

export default FaqExpand;
