import { FC } from "react";

import Carousel from "@components/Carousel";
import responsiveStyle from "@lib/theme/responsiveStyle";

import { DEFAULT_DESKTOP_HEIGHT, DEFAULT_MOBILE_HEIGHT } from "../constants";
import type { TBannersProps } from "../types";
import mapBannersToSlides from "../utils/mapBannersToSlides";

const Banners: FC<TBannersProps> = ({ isMobileVisible = true, isDesktopVisible = true, bannersData }) => {
  if (!bannersData) {
    return null;
  }

  const slides = mapBannersToSlides(bannersData.items);

  const desktopHeight = isDesktopVisible ? DEFAULT_DESKTOP_HEIGHT : 0;
  const mobileHeight = isMobileVisible ? DEFAULT_MOBILE_HEIGHT : 0;

  const speed = bannersData.sliderSpeed && bannersData.sliderSpeed * 1000;

  return (
    <Carousel
      isDesktopVisible={isDesktopVisible}
      isMobileVisible={isMobileVisible}
      slides={slides}
      autoplaySpeed={speed}
      height={responsiveStyle({ mobile: `${mobileHeight}px`, desktop: `${desktopHeight}px` })}
    />
  );
};
export default Banners;
