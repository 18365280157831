import { FC } from "react";

import { useMultiStyleConfig, Box } from "@chakra-ui/react";

import { Desktop, Mobile } from "@lib/media";

import CarouselSwiper from "./CarouselSwiper";
import type { TCarouselProps } from "./types";

// NOTE: known issue - slide flickers in Safari browsers when switching between slides faster

const Carousel: FC<TCarouselProps> = ({
  isDesktopVisible = true,
  isMobileVisible = true,
  height = "14rem",
  ...props
}) => {
  const styles = useMultiStyleConfig("Carousel", {});

  return (
    <Box sx={{ ...styles.carousel, height }} className="noscript-hide">
      {isMobileVisible && (
        <Mobile>
          <CarouselSwiper isMobile height={height} {...props} />
        </Mobile>
      )}
      {isDesktopVisible && (
        <Desktop>
          <CarouselSwiper isMobile={false} height={height} {...props} />
        </Desktop>
      )}
    </Box>
  );
};
export default Carousel;
