import { FC } from "react";

import Head from "next/head";

import { useRouter } from "@lib/router";
import useLocaleList from "@modules/layout/hooks/useLocaleList";
import getEnv from "@utils/getEnv";

type TLayoutHead = {
  title: string;
  description: string;
};

const LayoutHead: FC<TLayoutHead> = ({ title, description }) => {
  const { availableLanguages } = useLocaleList();
  const { defaultLocale, asPath } = useRouter();
  const hostname = getEnv(`NEXT_PUBLIC_DOMAIN_${defaultLocale?.toUpperCase()}`);

  return (
    <Head>
      <title>{title}</title>
      {availableLanguages.map((language, index) => (
        <link
          // eslint-disable-next-line react/no-array-index-key
          key={`linkKey-${index}`}
          rel="alternate"
          hrefLang={language.value}
          href={`https://${hostname}/${language.value}${asPath}`}
        />
      ))}
      <link key="linkKey-default" rel="alternate" hrefLang="x-default" href={`https://${hostname}${asPath}`} />
      <meta name="description" content={description} />
    </Head>
  );
};
export default LayoutHead;
