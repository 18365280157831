import { FC } from "react";

import { Box, useMultiStyleConfig, chakra } from "@chakra-ui/react";

import { LinkWrapper } from "@components/Link";

import ImageSlide from "./ImageSlide";
import SlideTextContent from "./SlideTextContent";
import { SlideType, TSlideProps } from "./types";
import VideoSlide from "./VideoSlide";

const Slide: FC<TSlideProps> = ({ slide, height, ...rest }) => {
  const styles = useMultiStyleConfig("Carousel", {});

  const SlideComponent = slide.url.type === SlideType.Video ? VideoSlide : ImageSlide;

  return (
    <Box sx={{ ...styles.slide, height }}>
      <LinkWrapper href={slide.linkUrl} isExternal anchorComponent={<chakra.a sx={styles.slideLink} />}>
        <>
          <SlideComponent url={slide.url.url} {...rest} />
          {slide.content && <SlideTextContent content={slide.content} />}
        </>
      </LinkWrapper>
    </Box>
  );
};
export default Slide;
