import { SlideType, TSlide, TextContentType } from "@components/Carousel";
import { TTextContent } from "@components/Carousel/types";

import { IBanner, textTypes } from "../models/banners";

const selectTextContentType = (responseContentType: string): TextContentType => {
  if (!textTypes.includes(responseContentType)) {
    return TextContentType.Content;
  }
  if (responseContentType === "title") {
    return TextContentType.Title;
  }
  if (responseContentType === "subtitle") {
    return TextContentType.Subtitle;
  }
  return TextContentType.Content;
};

const mapBannersToSlides = (banners: IBanner[]): TSlide[] =>
  banners.map((banner) => {
    const sortedTexts = banner.texts?.sort(
      ({ type: type1 }, { type: type2 }) => textTypes.indexOf(type1) - textTypes.indexOf(type2)
    );
    const content = sortedTexts?.reduce<TTextContent[]>(
      (acc, { type, text, fontSizeMobile, fontSizeDesktop, fontColorMobile, fontColorDesktop }) => {
        if (!text) {
          return acc;
        }
        acc.push({
          type: selectTextContentType(type),
          text,
          fontSizeMobile: fontSizeMobile || undefined,
          fontSizeDesktop: fontSizeDesktop || undefined,
          fontColorMobile: fontColorMobile || undefined,
          fontColorDesktop: fontColorDesktop || undefined,
        });
        return acc;
      },
      []
    );

    const { desktopImage, mobileImage } = banner;

    return {
      desktopUrl: desktopImage && {
        url: desktopImage.path,
        type: desktopImage.type === "video" ? SlideType.Video : SlideType.Image,
      },
      mobileUrl: mobileImage && {
        url: mobileImage.path,
        type: mobileImage.type === "video" ? SlideType.Video : SlideType.Image,
      },
      linkUrl: banner.link || undefined,
      content,
    };
  });

export default mapBannersToSlides;
