import { FC } from "react";

import { SystemProps, useMultiStyleConfig } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { Divider, FullWidthVStack } from "@components/common";
import Link from "@components/Link";
import { Desktop, Mobile } from "@lib/media";
import { ROUTES } from "@utils/constants";

import { TFaq } from "../types";
import FaqExpand from "./FaqExpand";

type TFaqListProps = {
  faq: TFaq[] | null;
  mobileSpacing?: SystemProps["margin"];
  spacing?: SystemProps["margin"];
  isFaqPage?: boolean;
};

const getKey = (index: number) => `faq-question-list-${index}`;

const FaqList: FC<TFaqListProps> = ({ faq, spacing = "1.875rem", mobileSpacing = "2rem", isFaqPage = false }) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("Faq", {});
  return faq?.length ? (
    <>
      <Desktop>
        <FullWidthVStack spacing={spacing} mb={spacing}>
          {!isFaqPage && <Divider borderBottomWidth="2px" />}
          {faq.map(({ question, answer }, index) => (
            <FaqExpand key={getKey(index)} id={getKey(index)} question={question} answer={answer} />
          ))}

          {!isFaqPage && (
            <Link
              id="faq-show-all"
              href={ROUTES.FAQ}
              sx={styles.linkStyle}
              variant="red"
              label={t("faq-link-show_all")}
            />
          )}
        </FullWidthVStack>
      </Desktop>
      {isFaqPage && (
        <Mobile>
          <FullWidthVStack spacing={mobileSpacing}>
            {faq.map(({ question, answer }, index) => (
              <FaqExpand key={getKey(index)} id={getKey(index)} question={question} answer={answer} />
            ))}
          </FullWidthVStack>
        </Mobile>
      )}
    </>
  ) : null;
};

export default FaqList;
