import { FC, useEffect, useRef } from "react";

import { Box, useMultiStyleConfig } from "@chakra-ui/react";

import type { TSlideTypeProps } from "./types";

const VideoSlide: FC<TSlideTypeProps> = ({ url, isVisible }) => {
  const styles = useMultiStyleConfig("Carousel", {});

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoPauseHandleRef = useRef<number | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (!isVisible) {
      // Wait until slide animation ends to reset video
      videoPauseHandleRef.current = window.setTimeout(() => {
        video.pause();
        video.currentTime = 0;
      }, 500);
      return;
    }

    // Interrupt pause delay if we are back again at this slide
    if (videoPauseHandleRef.current !== null) {
      window.clearTimeout(videoPauseHandleRef.current);
      videoPauseHandleRef.current = null;
    }
    video.play();
  }, [isVisible]);

  return (
    <Box sx={styles.videoSlide}>
      <video ref={videoRef} muted loop controls={false}>
        <source src={url} type="video/mp4" />
      </video>
    </Box>
  );
};
export default VideoSlide;
